import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import MovieList from './components/MovieList';
import MoviePlayer from './components/MoviePlayer';
import Navbar from './components/Navbar';
import SearchBar from './components/SearchBar';
import MovieListByTag from './components/MovieListByTag';
import moviesData from './data/moviesData';
import ReactPaginate from 'react-paginate';
import Overlay from './components/Overlay';
import './App.css';

// Extract unique tags (platforms) from the movies data
const getUniqueTags = (movies) => {
  const tags = movies.flatMap(movie => movie.tags);
  return [...new Set(tags)];
};

const getRandomMovies = (movies, count) => {
  const shuffled = movies.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const monetizationLink = "https://t.antj.link/246207/3785/0?bo=2753,2754,2755,2756&pyt=multi&po=6456&aff_sub5=SF_006OG000004lmDN";

const App = () => {
  const [filteredMovies, setFilteredMovies] = useState(getRandomMovies(moviesData, moviesData.length));
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPlatform, setSelectedPlatform] = useState('All');
  const moviesPerPage = 20;
  const navigate = useNavigate();
  const location = useLocation();

  const platforms = getUniqueTags(moviesData);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };

  const handleSearch = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = moviesData.filter(movie =>
      movie.Fulltitle.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredMovies(filtered);
    setCurrentPage(0);
  };

  const handleHomeClick = () => {
    setCurrentPage(0);
    navigate('/');
  };

  const handleRandomMovies = () => {
    setFilteredMovies(getRandomMovies(moviesData, moviesData.length));
    setCurrentPage(0);
  };

  // Filter movies by selected platform
  useEffect(() => {
    if (selectedPlatform === 'All') {
      setFilteredMovies(moviesData);
    } else {
      const filtered = moviesData.filter(movie => movie.tags.includes(selectedPlatform));
      setFilteredMovies(filtered);
    }
    setCurrentPage(0);
  }, [selectedPlatform]);

  const totalPages = Math.ceil(filteredMovies.length / moviesPerPage);
  const currentMovies = filteredMovies.slice(
    currentPage * moviesPerPage,
    (currentPage + 1) * moviesPerPage
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const handleRedirect = () => {
    window.location.href = 'https://mms.indianseries.site/'; // Replace with your URL
  };

  return (
    <div className="app">
      {/* <Overlay monetizationLink={monetizationLink} /> */}

      <Navbar onHomeClick={handleHomeClick} />
      <div className="content">
        <div className='buttons'>  <button className='random-button mms-btn' onClick={handleRedirect}>Hidden Cam Videos <i class="fas fa-video"></i></button>
        <button className='random-button' onClick={handleRandomMovies}>Refresh List <i class="fas fa-sync-alt"></i>
        </button></div>
      
        {location.pathname === '/' && <SearchBar onSearch={handleSearch} />}

        {/* Dropdown to select platform */}
        {location.pathname === '/' && (
          <div className="filter-container">
            <label htmlFor="platform-filter">Filter by Platform: </label>
            <select
              id="platform-filter"
              value={selectedPlatform}
              onChange={(e) => setSelectedPlatform(e.target.value)}
            >
              <option value="All">All Platforms</option>
              {platforms.map((platform, index) => (
                <option key={index} value={platform}>{platform}</option>
              ))}
            </select>
          </div>
        )}

        {/* {location.pathname === '/' && (
          <div style={{ textAlign: "center" }}>
            <h2>
              <a style={{ textDecoration: "none", color: "wheat" }} href='https://t.me/indianhotwebseries_site'>
                Join Telegram For Clips
              </a>
            </h2>
          </div>
        )} */}

        <Routes>
          <Route
            path="/"
            element={<MovieList movies={currentMovies} onSelectMovie={handleSelectMovie} />}
          />
          <Route
            path="/movie/:title"
            element={<MoviePlayer movies={moviesData} />}
          />
          <Route path="/movies/tag/:tag" element={<MovieListByTag movies={moviesData} onSelectMovie={handleSelectMovie} />} />
        </Routes>

        {location.pathname === '/' && (
          <div className="pagination-container">
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item previous"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              forcePage={currentPage} 
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
